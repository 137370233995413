<template>
  <el-dialog v-model="dialogVisible" :title="dialogtitle" :close-on-click-modal="false" width="730px">
    <!-- {{ state.Notice }} -->
    <el-table ref="tableRef" :data="state.Notice" class="mb-20" :header-cell-style="{
      background: '#EAEAEA',
      color: config.table.color,
      fontWeight: config.table.fontWeight,
      fontSize: config.table.fontSize,
    }" row-key="id" @selection-change="selectionChange" @select="state.type = '3'" @select-all="state.type = '3'">
      <el-table-column :align="'center'" type="selection" width="55" />
      <el-table-column :align="'center'" type="index" label="序号" width="80" />
      <el-table-column :align="'center'" prop="showfileName" label="文件名称" />
      <!-- <el-table-column :align="'center'" prop="signature" label="签署方名称" /> -->
    </el-table>
    <div class="title mb-20">应用页面</div>
    <el-select class="mb-10" placeholder=请选择应用页面类型 v-model="state.type" @change="TypeChange">
      <el-option v-for="(el, ind) in state.TypeList" :key="ind" :value="el.value" :label="el.label" />
    </el-select>
    <el-input disabled v-model="state.showpages" :maxlength="config.inputMaxlength" show-word-limit />
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="batchSeal()"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, watch,computed } from 'vue'
import { getXialaListdata2 } from "@/utils/server/selectdata.js"
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
const tableRef = ref()
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  Notice: [],
  type: '',
  TypeList: getXialaListdata2(['全部页面', '奇数页', '偶数页', '自定义页面范围'], ['0', '1', '2', '3']),
  pages: [],
  showpages: '',
  selectionCount: 0,
  selectionData: [],
})
const userInfo =computed(()=>{
    return JSON.parse(window.sessionStorage.user).user_info
})
watch(
  () => state.selectionCount,
  (value, oldValue) => {
    setDialogtitle(state.selectionCount)
  }
)
const batchSeal=()=>{
  emit('Emit',state.selectionData)
  dialogVisible.value = false
}
const setDialogtitle = (val) => {
  dialogtitle.value = '批量应用   已选择(' + val + ')'
}
// 表格选中事件
const selectionChange = (val) => {
  console.log('选中改变', val)
  // state.type='3'
  state.selectionCount = val.length
  state.selectionData = val
  state.showpages = ''
  val.forEach((item, ind) => {
    if (ind == val.length - 1)
      state.showpages += item.pageIndex
    else
      state.showpages += item.pageIndex + ','
  })
}
// 下拉选择改变事件
const TypeChange = (val) => {
  if (val != 3)
    unref(tableRef).clearSelection()
  // 全部页面
  if (val == 0) {
    //已全选停止操作
    if (state.selectionCount == state.Notice.length)
      return
    state.Notice.forEach(item => {
      unref(tableRef).toggleRowSelection(item)
    })
  } else if (val == 1) {//奇数页
    state.Notice.forEach((item, index) => {
      if (index % 2 == 0)
        unref(tableRef).toggleRowSelection(item)
    })
  } else if (val == 2) {//偶数页
    state.Notice.forEach((item, index) => {
      if (index % 2 != 0)
        unref(tableRef).toggleRowSelection(item)
    })
  }
}
const show = (( baseInfo,fileImgs, el) => {
  setDialogtitle(0)
  dialogVisible.value = true
  console.log('fileImgs22',fileImgs)
  state.Notice = fileImgs.pages.map((item, ind) => {
    return {
      pageIndex: ind + 1,
      showfileName:fileImgs.fileChname+'第'+(ind+1)+'页'
      // fileName: item.fileChname,
      // signature: userInfo.sysCompany?userInfo.sysCompany.companyName:'/',
    }
  })
})

defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
::v-deep .el-input,
.el-input {
  height: 48px;
}

::v-deep .el-dialog__title,
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333 !important;
}</style>